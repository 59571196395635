<template>
    <div>
      <v-snackbar app="true" top rounded="pill" :color="color" v-model="localSnackbar" @timeout="closeSnackbar">
        {{ text }}
        <template v-slot:action="{ attrs }">
          <v-btn v rounded text v-bind="attrs" @click="closeSnackbar">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </template>
  
  <script>
  export default {
    props: ['snackbar', 'text', 'color'],
    data() {
      return {
        localSnackbar: this.snackbar
      };
    },
    watch: {
      snackbar(newVal) {
        this.localSnackbar = newVal;
      },
      localSnackbar(newVal) {
        this.$emit('updateSnackbar', newVal);
      }
    },
    methods: {
      closeSnackbar() {
        this.localSnackbar = false;
      }
    }
  };
  </script>